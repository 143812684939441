
import { Audits } from "@/api";
import { Audit } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import InstructorsList from "@/components/instructors/InstructorsList.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    InstructorsList,
    PageTitle,
    ValidationProvider,
    ValidationObserver
  }
})
export default class CreateAuditPage extends Vue {
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Save",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "save"
    }
  ];
  headersTitle = [{ icon: "mdi-calendar-month", header: "Create Audit" }];
  selectedInstructors: Array<number> = [];
  audit: Audit = {
    courseDurationId: undefined,
    instructors: [],
    name: "",
    price: 0
  };
  durations = [];

  async created() {
    try {
      this.durations = await Audits.durations();
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  async save() {
    try {
      if (!this.audit.price || this.audit.price <= 0) {
        (this.$refs.price as InstanceType<
          typeof ValidationProvider
        >).applyResult({
          errors: ["is requared"], // array of string errors
          // valid: false, // boolean state
          failedRules: {} // should be empty since this is a manual error.
        });
        return;
      }
      const data = {
        name: this.audit.name,
        instructors: this.selectedInstructors,
        price: this.audit.price,
        courseDurationId: this.audit.courseDurationId
      };

      await Audits.create(data);
      await this.$success("<strong>Success!</strong> Audit has been created!");

      if (this.$route.name === "CreateAudit") {
        await this.$router.push({ name: "AuditsList" });
      }
    } catch (e) {
      const err = e as any;
      (this.$refs.auditForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
      await this.$error(err.errors);
    }
  }

  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }

  async onGoBack() {
    await this.$router.push({ name: "AuditsList" });
  }
}
